import { extraHeaders } from "~/core/extra-headers";

export class RustError extends Error {
	__proto__: Error;
	public extra: any;
	public status: number;
	constructor(message: string, status: number, extra?: any) {
		const trueProto = new.target.prototype;
		super(message);
		this.name = "RustError";
		this.__proto__ = trueProto;
		this.status = status;
		this.extra = extra != null ? extra : {};
	}
}

export async function handleNonOkResponse(response: Response): Promise<never> {
	const contentType = response.headers.get("content-type");
	if (contentType != "application/json") {
		const error: RustError = new RustError(await response.text(), response.status);
		throw error;
	} else {
		const json = await response.json();
		const error: RustError = new RustError(json.message, response.status, json);
		throw error;
	}
}

export function throwFetchError(reason: any): never {
	const safeCallerText = reason.message ? reason.message : "Unknown error";
	throw new RustError(safeCallerText, -1, reason);
}

type RequestInitApi = {
	headers?: Record<string, string>;
	body?: string;
	cache?: RequestCache;
	referrerPolicy: ReferrerPolicy;
	mode: RequestMode;
	credentials: RequestCredentials;
	method: string;
};

export function fetchApi(url: string, init: RequestInitApi): Promise<Response> {
	const headers = { ...(url.startsWith("/") ? extraHeaders : {}), ...init.headers };
	return fetch(url, { ...init, headers })
}
