import IntlMessageFormat, { FormatXMLElementFn } from "intl-messageformat";
import React from "react";
import { strings } from "./strings";

const FALLBACK_LANGUAGE = "en";

const locale = new Intl.Locale(navigator.language);

// Add temporary I18N keys during development here.
// Please don't merge temporary I18N keys into the master branch.
const TMP_KEYS = {
	da: {},
	de: {},
	en: {},
	fr: {},
	pl: {},
};

for (const lang in TMP_KEYS) {
	const values = TMP_KEYS[lang];
	for (const key in values) {
		strings[lang][key] = values[key];
	}
}

function mergeChunks(chunks: React.ReactNode[]) {
	if (chunks.length == 1) {
		return chunks[0];
	}
	return React.Children.toArray(chunks);
}
type Options = Record<
	string,
	string | number | React.ReactNode | FormatXMLElementFn<React.ReactNode>
>;
function format(key: string, options: Options = {}) {
	let language = locale.language;
	// Language does not exist, use fallback.
	if (strings[language] == null) {
		language = FALLBACK_LANGUAGE;
	}
	let text = strings[language][key];
	// Specific string did not exist in language, use fallback.
	if (text == null) {
		language = FALLBACK_LANGUAGE;
		text = strings[FALLBACK_LANGUAGE][key];
	}
	// Key did not exist in fallback languae, report it to log.
	if (text == null) {
		console.warn(
			`Text was not available in FALLBACK_LANGUAGE(${FALLBACK_LANGUAGE}), writing key: ${key}`,
		);
		return key;
	}
	const formatter = new IntlMessageFormat(text, language);
	for (const [k, o] of Object.entries(options)) {
		if (typeof o === "function") {
			options[k] = (chunks) => o([mergeChunks(chunks)]);
		}
	}
	const result = formatter.format<React.ReactNode>(options);
	if (!Array.isArray(result)) {
		return result;
	}
	return mergeChunks(result);
}

function getText(key: string, options: Record<string, string | number> = {}) {
	const node = format(key, options);
	if (node == null) {
		return "";
	}
	return node.toString();
}

const i18n = {
	getText,
	format,
};

export default i18n;
