import classNames from "classnames";
import React, { ComponentPropsWithoutRef } from "react";
import classes from "./button.module.scss";

type Props = {
	color?: "none" | "blue" | "red" | "grey";
	primary?: boolean;
	secondary?: boolean;
	tertiary?: boolean;
	expandWidth?: boolean;
} & ComponentPropsWithoutRef<"button">;

/**
 * General button component.
 * Primary buttons are filled, secondary buttons are outlined,
 * tertiary buttons look like colored text (or only an icon).
 *
 * If you want to get the statevent stuff but use the button for something bespoke,
 * you can use the `unstyled` prop to remove the default styling.
 */
const Button: React.FC<Props> = React.forwardRef<HTMLButtonElement>(
	(
		{
			className,
			expandWidth,
			color = "blue",
			primary = true,
			secondary,
			tertiary,
			onClick,
			children,
			...props
		}: Props,
		ref,
	) => (
		<button
			ref={ref}
			className={classNames(
				classes.button,
				!tertiary && primary && classes.primary,
				!tertiary && secondary && classes.secondary,
				tertiary && classes.tertiary,
				expandWidth && classes.expandWidth,
				classes[color],
				className,
			)}
			onClick={(e) => {
				if (onClick) onClick(e);
			}}
			{...props}
		>
			{children}
		</button>
	),
);

Button.displayName = "ButtonComponent";
export default Button;
