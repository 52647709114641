function getQueryParam(key: string): string | undefined {
	const params = new URLSearchParams(window.location.search);
	// Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
	return params.get(key) ?? undefined;
}

function setProvider(p?: string) {
	const searchParams = new URLSearchParams(window.location.search);
	searchParams.set("provider", p ?? "");
	const newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}`;
	history.pushState(null, "", newRelativePathQuery);
	localStorage.setItem("provider", p ?? "");
}

function deleteProvider() {
	const searchParams = new URLSearchParams(window.location.search);
	searchParams.delete("provider");
	const newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}`;
	history.pushState(null, "", newRelativePathQuery);
	localStorage.removeItem("provider");
}

export default { getQueryParam, setProvider, deleteProvider };
