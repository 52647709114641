import { useState } from "react";
import i18n from "~/i18n";
import { useErrorHandler } from "~/toast-handler";
import Button from "../button";
import { FetchError, post } from "../fetch";
import { Column } from "../flex";
import Input from "../input";
import Label from "../label";
import redirect from "../redirect";
import { useSharedState } from "../state";
import ToRegularLogin from "../to-regular-login";

export default function ResetPassword() {
	const [state, setState] = useSharedState();
	const [password, setPassword] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");

	const { sendError } = useErrorHandler();
	return (
		<form
			onSubmit={async (e) => {
				e.preventDefault();

				const { resetToken } = state;
				if (resetToken == null) {
					setState((prev) => ({ ...prev, page: "regular" }));
					return;
				}
				try {
					await post("/py/user/newPassword", {
						code: resetToken,
						password1: password,
						password2: passwordConfirm,
					});
					// newPasswords sets the session cookie so redirect.
					redirect();
				} catch (e: unknown) {
					const error = e as FetchError;
					sendError(error.message);
				}
			}}
		>
			<Column gap="600" flex="1 0 auto" style={{ width: "100%" }}>
				<span style={{ fontSize: "0.8rem" }}>
					{i18n.getText("weblogin.reset_password.greeting", {
						name: state.name ?? "Unknown",
					})}
				</span>
				<Label id="password" label={i18n.getText("weblogin.reset_password.password.label")}>
					{(id) => (
						<Input
							id={id}
							required
							type="password"
							autoComplete="new-password"
							placeholder={i18n.getText(
								"weblogin.reset_password.password.placeholder",
							)}
							value={password}
							onChange={setPassword}
							autoFocus
						/>
					)}
				</Label>
				<Label
					id="passwordConfirm"
					label={i18n.getText("weblogin.reset_password.confirm_password.label")}
				>
					{(id) => (
						<Input
							id={id}
							required
							type="password"
							autoComplete="new-password"
							placeholder={i18n.getText(
								"weblogin.reset_password.confirm_password.placeholder",
							)}
							value={passwordConfirm}
							onChange={setPasswordConfirm}
						/>
					)}
				</Label>
				<Button
					type="submit"
					style={{ marginLeft: "auto" }}
					disabled={password.length == 0 || password != passwordConfirm}
				>
					{i18n.getText("weblogin.reset_password.reset_password")}
				</Button>
				<ToRegularLogin />
			</Column>
		</form>
	);
}
