import i18n from "~/i18n";
import { useErrorHandler } from "~/toast-handler";
import Button from "../button";
import { FetchError, post } from "../fetch";
import { Column } from "../flex";
import Input from "../input";
import Label from "../label";
import { useSharedState } from "../state";
import ToRegularLogin from "../to-regular-login";

export default function ForgotPassword() {
	const [state, setState] = useSharedState();
	const { email } = state;
	const setEmail = (email: string) => {
		setState((prev) => ({ ...prev, email }));
	};
	const { sendError } = useErrorHandler();
	return (
		<form
			onSubmit={async (e) => {
				e.preventDefault();
				try {
					await post("/py/user/resetPassword", { usernameOrEmail: email });
					setState((prev) => ({ ...prev, page: "verify-token" }));
				} catch (e: unknown) {
					const error = e as FetchError;
					sendError(error.message);
				}
			}}
		>
			<Column gap="600" flex="1 0 auto">
				<Label id="email" label={i18n.getText("weblogin.forgot_password.email.label")}>
					{(id) => (
						<Input
							id={id}
							type="email"
							autoComplete="email"
							placeholder={i18n.getText("weblogin.forgot_password.email.placeholder")}
							value={email}
							onChange={setEmail}
							autoFocus
						/>
					)}
				</Label>
				<Button type="submit" style={{ marginLeft: "auto" }} disabled={email.length == 0}>
					{i18n.getText("weblogin.forgot_password.reset_password")}
				</Button>
				<ToRegularLogin />
			</Column>
		</form>
	);
}
