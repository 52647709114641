import i18n from "~/i18n";
import { useErrorHandler } from "~/toast-handler";
import util from "~/util";
import Button from "../button";
import { Column } from "../flex";
import Label from "../label";
import { ssoLogin } from "../login";
import redirect from "../redirect";
import Select from "../select";
import { useSharedState } from "../state";
import ToRegularLogin from "../to-regular-login";

export default function SSOLogin() {
	const [state, setState] = useSharedState();
	const { sso, ssoProviders } = state;
	const setSso = (sso: string) => {
		setState((prev) => ({ ...prev, sso }));
		util.setProvider(sso);
	};

	const { sendWarning, sendError } = useErrorHandler();
	return (
		<form
			onSubmit={async (e) => {
				e.preventDefault();
				if (sso == null) {
					sendWarning(i18n.getText("weblogin.warning.no_sso_selected"));
					return;
				}
				const response = await ssoLogin(sso);
				if (response.ok) {
					redirect();
				} else {
					if (response.message != null) {
						sendError(response.message!);
					} else {
						sendError(
							i18n.format(response.message_i18n!, {
								inner_message: response.inner_message ? (
									<span>
										{i18n.format("weblogin.sso_login_failed_inner", {
											em: (chunks) => <em>{chunks}</em>,
											br: () => <br />,
											inner_message: response.inner_message,
										})}
									</span>
								) : (
									<></>
								),
								final_message: response.final_message_i18n ? (
									<span>
										{i18n.format(response.final_message_i18n, {
											br: () => <br />,
										})}
									</span>
								) : (
									<></>
								),
							}),
						);
					}
				}
			}}
		>
			<Column gap="600" flex="1 0 auto">
				<Label id="sso" label={i18n.getText("weblogin.sso_login.organization.label")}>
					{(id) => (
						<Select
							id={id}
							required
							placeholder={i18n.getText(
								"weblogin.sso_login.organization.placeholder",
							)}
							values={ssoProviders!}
							value={sso}
							onChange={setSso}
							autoFocus
						/>
					)}
				</Label>
				<Button style={{ marginLeft: "auto" }} type="submit" disabled={sso == null}>
					{i18n.getText("weblogin.sso_login.login")}
				</Button>
				<ToRegularLogin />
			</Column>
		</form>
	);
}
