import { Property } from "csstype";
import React, { ComponentPropsWithoutRef, CSSProperties } from "react";

type Size = "none" | "100" | "200" | "300" | "400" | "500" | "600" | "700";
type Sides = { top?: Size; right?: Size; bottom?: Size; left?: Size };

type CommonProps = {
	// CSSProps unpacked
	flexWrap?: Property.FlexWrap;
	justifyContent?: Property.JustifyContent;
	alignItems?: Property.AlignItems;
	alignContent?: Property.AlignContent;
	alignSelf?: Property.AlignSelf;
	flex?: Property.Flex;
	order?: Property.Order;

	// These needs to be converted
	gap?: Size | Size[];
	padding?: Size | Size[] | Sides;
	margin?: Size | Size[] | Sides;
	inline?: boolean;
} & ComponentPropsWithoutRef<"div">;

type FlexProps = CommonProps & {
	flexDirection?: Property.FlexDirection;
};

function convertSideGap(
	style: CSSProperties,
	value: Size | Size[] | Sides,
	type: "padding" | "margin",
	conversion: (size: Size) => string,
) {
	if (Array.isArray(value)) {
		style[type] = value.map(conversion).join(" ");
	} else if (typeof value === "object") {
		for (const [k, v] of Object.entries(value)) {
			style[type + k.charAt(0).toUpperCase() + k.slice(1)] = conversion(v);
		}
	} else {
		style[type] = conversion(value);
	}
}

/**
 * A CSS Flexbox thin-wrapper for react.
 **/
const Flex: React.FC<FlexProps> = ({ gap, padding, margin, inline, style, children, ...props }) => {
	// We must extract the flex style props from the rest of the div props.
	const {
		flexDirection,
		flexWrap,
		justifyContent,
		alignItems,
		alignContent,
		alignSelf,
		flex,
		order,
		...divProps
	} = props;
	const computedStyle: CSSProperties = {
		flexDirection,
		flexWrap,
		justifyContent,
		alignItems,
		alignContent,
		alignSelf,
		flex,
		order,
	};

	if (gap != null) {
		computedStyle.gap = (Array.isArray(gap) ? gap : [gap]).map(convertGap).join(" ");
	}
	if (padding != null) {
		convertSideGap(computedStyle, padding, "padding", convertPadding);
	}
	if (margin != null) {
		convertSideGap(computedStyle, margin, "margin", convertMargin);
	}
	computedStyle.display = inline ? "inline-flex" : "flex";

	return (
		<div {...divProps} style={{ ...computedStyle, ...style }}>
			{children}
		</div>
	);
};
export default Flex;

type RowProps = CommonProps & {
	reverse?: boolean;
	height?: Size;
};

/**
 * A Flexbox row thin-wrapper for react.
 * Rows default to `alignItems="center"`.
 * Rows default to `height="medium"` (26px).
 **/
export const Row: React.FC<RowProps> = ({ children, reverse, height = "400", ...props }) => {
	const cHeight = convertHeight(height);
	if (props.style) {
		props.style.height = cHeight;
	} else {
		props.style = { height: cHeight };
	}

	// default row to centering on the cross-axis.
	if (props.alignItems == null) {
		props.alignItems = "center";
	}

	return (
		<Flex {...props} flexDirection={reverse ? "row-reverse" : "row"}>
			{children}
		</Flex>
	);
};

type ColumnProps = CommonProps & {
	reverse?: boolean;
};

/**
 * A Flexbox column thin-wrapper for react.
 **/
export const Column: React.FC<ColumnProps> = ({ children, reverse, ...props }) => {
	return (
		<Flex {...props} flexDirection={reverse ? "column-reverse" : "column"}>
			{children}
		</Flex>
	);
};

// TODO(mikkel): Unify these variables with the scss variables defined in styles/vars.scss
const convertGap = (size: Size): string => {
	switch (size) {
		case "none":
			return "0";
		case "100":
			return "0.25rem";
		case "200":
			return "0.5rem";
		case "300":
			return "1rem";
		case "400":
			return "1.5rem";
		case "500":
			return "2rem";
		case "600":
			return "3rem";
		case "700":
			return "5rem";
	}
};
const convertPadding = (size: Size): string => {
	switch (size) {
		case "none":
			return "0px";
		case "100":
			return "0.5rem";
		case "200":
			return "0.75rem";
		case "300":
			return "1rem";
		case "400":
			return "1.5rem";
		case "500":
			return "2rem";
		case "600":
			return "3rem";
		case "700":
			return "4rem";
	}
};
const convertMargin = convertPadding;
const convertHeight = (size: Size): string => {
	switch (size) {
		case "none":
			return "none";
		case "100":
			return "0.75rem";
		case "200":
			return "1rem";
		case "300":
			return "1.5rem";
		case "400":
			return "2rem";
		case "500":
			return "3rem";
		case "600":
			return "4rem";
		case "700":
			return "4.5rem";
	}
};
