import RightArrow from "/assets/arrow_right.svg?react";
import Button from "./button";
import i18n from "./i18n";
import { useSharedState } from "./state";
import util from "./util";

export default function ToRegularLogin() {
	const [_, setState] = useSharedState();
	return (
		<Button
			type="button"
			expandWidth
			tertiary
			style={{ marginTop: "auto" }}
			onClick={() => {
				setState((prev) => ({ ...prev, page: "regular" }));
				util.deleteProvider();
			}}
		>
			<span>{i18n.getText("weblogin.button.to_regular_login")}</span>
			<RightArrow width="20px" height="20px" />
		</Button>
	);
}
