import * as RToast from "@radix-ui/react-toast";
import { useEffect } from "react";
import SCALGOLogo from "/assets/scalgo_logo.svg?react";
import { isLoggedIn } from "./api_endpoints/login";
import { getProviders } from "./api_endpoints/sso";
import { post } from "./fetch";
import i18n from "./i18n";
import classes from "./main.module.scss";
import ForgotPassword from "./pages/forgot-password";
import LoggedIn from "./pages/logged-in";
import RegularLogin from "./pages/regular-login";
import ResetPassword from "./pages/reset-password";
import SSOLogin from "./pages/sso-login";
import VerifyToken from "./pages/verify-token";
import { useSharedState } from "./state";
import ToastHandler, { useErrorHandler } from "./toast-handler";
import util from "./util";

export default function Main() {
	const [_, setState] = useSharedState();
	const { sendError, sendWarning } = useErrorHandler();
	useEffect(() => {
		const fn = async () => {
			let loggedIn = false;
			try {
				await isLoggedIn();
				setState((prev) => ({ ...prev, page: "logged-in" }));
				loggedIn = true;
			} catch {
				// Do nothing;
			}

			getProviders()
				.then((json) => {
					const providers = json.filter(({ provider }) => provider.length > 0);
					// Make a list of sso providers.
					const ssoProviders = providers
						.map((p) => ({ value: p.provider, label: p.name }))
						.sort((a, b) => a.label.localeCompare(b.label));
					// Maybe preselect a provider in the list.
					let sso = util.getQueryParam("provider");
					if (sso == null) {
						sso = localStorage.getItem("provider") ?? undefined;
					}
					if (sso == null) {
						sso = providers.find((p) => p.selected == true)?.provider;
					}
					setState((prev) => {
						let page = prev.page;
						if (!loggedIn && sso != null) {
							page = "sso";
							util.setProvider(sso);
						}
						if (sso == "") {
							sso = undefined;
						}
						return { ...prev, page, sso, ssoProviders };
					});
				})
				.catch((_e) => {
					sendError(i18n.getText("weblogin.error.connection"));
				});

			const resetToken = util.getQueryParam("resetToken");
			if (resetToken != null) {
				post<{ name: string }>("/py/user/resetPasswordVerify", { code: resetToken })
					.then(({ name }) => {
						setState((prev) => ({ ...prev, page: "reset-password", resetToken, name }));
					})
					.catch((_e) => {
						sendWarning("weblogin.warning.token_not_verified");
					});
			}
		};
		fn();
	}, []);
	return (
		<RToast.Provider swipeDirection="down">
			<div className={classes.container}>
				<SCALGOLogo className={classes.logo} />
				<Page />
			</div>
			<ToastHandler />
			<RToast.Viewport className={classes.toastViewPort} />
		</RToast.Provider>
	);
}

function Page() {
	const [state, _] = useSharedState();
	switch (state.page) {
		case "logged-in": {
			return <LoggedIn />;
		}
		case "regular": {
			return <RegularLogin />;
		}
		case "sso": {
			return <SSOLogin />;
		}
		case "forgot-password": {
			return <ForgotPassword />;
		}
		case "verify-token": {
			return <VerifyToken />;
		}
		case "reset-password": {
			return <ResetPassword />;
		}
	}
}
