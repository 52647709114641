import * as RSelect from "@radix-ui/react-select";
import classNames from "classnames";
import React from "react";
import Chevron from "/assets/chevron.svg?react";
import Tick from "/assets/tick.svg?react";
import classes from "./select.module.scss";

type Props = {
	id: string;
	placeholder: string;
	values: { value: string; label: string }[];
	value?: string;
	onChange: (value: string) => void;
	required?: boolean;

	autoFocus?: boolean;
};

export default function Select({
	id,
	required,
	autoFocus,
	placeholder,
	values,
	value,
	onChange,
}: Props) {
	return (
		<RSelect.Root required={required} value={value} onValueChange={onChange}>
			<RSelect.Trigger id={id} className={classes.trigger} autoFocus={autoFocus}>
				<RSelect.Value placeholder={placeholder} />
				<RSelect.Icon className={classes.icon}>
					<Chevron />
				</RSelect.Icon>
			</RSelect.Trigger>
			<RSelect.Portal>
				<RSelect.Content className={classes.content} position="popper" sideOffset={-150}>
					<RSelect.ScrollUpButton className={classes.scrollButton}>
						<Chevron style={{ transform: "rotate(180deg)" }} />
					</RSelect.ScrollUpButton>
					<RSelect.Viewport className={classes.viewport}>
						{values.map((v) => (
							<SelectItem key={v.value} value={v.value}>
								{v.label}
							</SelectItem>
						))}
					</RSelect.Viewport>
					<RSelect.ScrollDownButton className={classes.scrollButton}>
						<Chevron />
					</RSelect.ScrollDownButton>
				</RSelect.Content>
			</RSelect.Portal>
		</RSelect.Root>
	);
}

const SelectItem = React.forwardRef<
	React.ElementRef<typeof RSelect.Item>,
	React.ComponentPropsWithoutRef<typeof RSelect.Item>
>(({ children, className, ...props }, forwardedRef) => {
	return (
		<RSelect.Item className={classNames(classes.item, className)} {...props} ref={forwardedRef}>
			<RSelect.ItemText>{children}</RSelect.ItemText>
			<RSelect.ItemIndicator className={classes.itemIndicator}>
				<Tick />
			</RSelect.ItemIndicator>
		</RSelect.Item>
	);
});
SelectItem.displayName = "SelectItem";
