import { HTMLInputTypeAttribute } from "react";
import classes from "./input.module.scss";

type Props = {
	id: string;
	type: HTMLInputTypeAttribute;
	placeholder?: string;
	autoFocus?: boolean;
	autoComplete?: string;
	value: string;
	onChange: (value: string) => void;
	required?: boolean;
	onEnter?: () => void;
};

export default function Input({
	id,
	required,
	type,
	placeholder,
	autoFocus,
	autoComplete,
	value,
	onChange,
	onEnter,
}: Props) {
	return (
		<input
			id={id}
			type={type}
			placeholder={placeholder}
			autoComplete={autoComplete}
			value={value}
			onChange={(e) => onChange(e.currentTarget.value)}
			className={classes.input}
			autoFocus={autoFocus}
			required={required}
			onKeyDown={(e) => {
				if (e.key == "Enter" && onEnter != null) onEnter();
				if (e.ctrlKey && e.key == "u") {
					// We preventDefault to stop browsers from opening source code view.
					e.preventDefault();
					onChange("");
				}
			}}
		/>
	);
}
