import { post } from "~/fetch";
import i18n from "~/i18n";
import redirect from "~/redirect";
import RightArrow from "/assets/arrow_right.svg?react";
import Button from "../button";
import { Column } from "../flex";
import { useSharedState } from "../state";

export default function LoggedIn() {
	const [state, setState] = useSharedState();

	return (
		<Column gap="600" flex="1 0 auto" style={{ width: "100%" }}>
			<span style={{ fontSize: "0.8rem" }}>
				{i18n.getText("weblogin.logged_in.already_logged_in")}
			</span>
			<Button type="button" style={{ marginLeft: "auto" }} onClick={redirect}>
				{i18n.getText("weblogin.logged_in.continue")}
			</Button>
			<Button
				type="button"
				expandWidth
				tertiary
				style={{ marginTop: "auto" }}
				disabled={state.ssoProviders == null}
				onClick={async () => {
					await post("/py/liveLogin/logout");
					setState((prev) => ({ ...prev, page: prev.sso != null ? "sso" : "regular" }));
				}}
			>
				<span>{i18n.getText("weblogin.logged_in.logout")}</span>
				<RightArrow width="20px" height="20px" />
			</Button>
		</Column>
	);
}
