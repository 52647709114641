import * as RToast from "@radix-ui/react-toast";
import classNames from "classnames";
import { Info, useSharedState } from "./state";
import classes from "./toast-handler.module.scss";

export default function ToastHandler() {
	const [state, _setState] = useSharedState();

	const { information } = state;

	return (
		<>
			{Object.keys(information).map((id) => {
				return <Toast key={id} id={id} />;
			})}
		</>
	);
}

export type ToastMessage = React.ReactNode;

let infoId = 0;
export function useErrorHandler() {
	const [_, setState] = useSharedState();

	const sendInformation = (info: Info) => {
		setState((prev) => ({
			...prev,
			information: { ...prev.information, [infoId.toString()]: info },
		}));
		infoId += 1;
	};

	const sendInfo = (message: ToastMessage) =>
		sendInformation({ type: "info", message, duration: 10000 });
	const sendWarning = (message: ToastMessage) => sendInformation({ type: "warning", message });
	const sendError = (message: ToastMessage) => sendInformation({ type: "error", message });

	return { sendInfo, sendWarning, sendError };
}

function Toast({ id }: { id: string }) {
	const [state, setState] = useSharedState();
	const info = state.information[id];
	return (
		<RToast.Root
			duration={info.duration}
			className={classNames(classes.toastRoot, {
				[classes.info]: info.type == "info",
				[classes.warning]: info.type == "warning",
				[classes.error]: info.type == "error",
			})}
			onOpenChange={(open) => {
				if (open) return;
				setTimeout(() => {
					setState((prev) => {
						const information = { ...prev.information };
						delete information[id];
						return { ...prev, information };
					});
				}, 500);
			}}
		>
			<RToast.Description className={classes.toastDescription}>
				{info.message}
			</RToast.Description>
		</RToast.Root>
	);
}
