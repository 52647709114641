// Generated by bind_gen DO NOT EDIT
import { fetchApi, handleNonOkResponse, throwFetchError } from "./_utils";

export async function isLoggedIn() : Promise<void> {
	let __data__ = "";
	try {
		const r = await fetchApi(
			"/r/login/is_logged_in?" + __data__, {
			referrerPolicy: 'origin',
			mode: 'same-origin',
			credentials: 'same-origin',
			method: 'GET'
		});
		if (!r.ok) await handleNonOkResponse(r);
	} catch (e) {
		throwFetchError(e);
	}
}
