import * as RLabel from "@radix-ui/react-label";
import React from "react";
import { Column } from "./flex";
import classes from "./label.module.scss";

type Props = {
	id: string;
	label: string;
	children: (id: string) => React.ReactNode;
};

export default function Label({ id, label, children }: Props) {
	return (
		<Column>
			<RLabel.Root htmlFor={id} className={classes.label}>
				{label}
			</RLabel.Root>
			{children(id)}
		</Column>
	);
}
