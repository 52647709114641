import i18n from "~/i18n";
import { useErrorHandler } from "~/toast-handler";
import Button from "../button";
import { post } from "../fetch";
import { Column } from "../flex";
import Input from "../input";
import Label from "../label";
import { useSharedState } from "../state";
import ToRegularLogin from "../to-regular-login";

export default function VerifyToken() {
	const [state, setState] = useSharedState();
	const { resetToken } = state;
	const setResetToken = (resetToken: string) => {
		setState((prev) => ({ ...prev, resetToken }));
	};

	const { sendError } = useErrorHandler();
	return (
		<form
			onSubmit={async (e) => {
				e.preventDefault();
				if (resetToken == null) {
					sendError(i18n.getText("weblogin.error.reset_token_missing"));
					return;
				}
				try {
					const res = await post<{ name: string }>("/py/user/resetPasswordVerify", {
						code: resetToken,
					});
					setState((prev) => ({ ...prev, page: "reset-password", name: res.name }));
				} catch (e: unknown) {
					sendError(i18n.getText("weblogin.error.verify_failed"));
				}
			}}
		>
			<Column gap="600" flex="1 0 auto" style={{ width: "100%" }}>
				<span style={{ fontSize: "0.8rem" }}>
					{i18n.getText("weblogin.verify_token.info")}
				</span>
				<Label id="resetToken" label={i18n.getText("weblogin.verify_token.token.label")}>
					{(id) => (
						<Input
							id={id}
							required
							autoComplete="off"
							type="text"
							placeholder={i18n.getText("weblogin.verify_token.token.placeholder")}
							value={resetToken || ""}
							onChange={setResetToken}
							autoFocus
						/>
					)}
				</Label>
				<Button
					type="submit"
					style={{ marginLeft: "auto" }}
					disabled={resetToken?.length == 0}
				>
					{i18n.getText("weblogin.verify_token.continue")}
				</Button>
				<ToRegularLogin />
			</Column>
		</form>
	);
}
