import { useState } from "react";
import i18n from "~/i18n";
import { useErrorHandler } from "~/toast-handler";
import util from "~/util";
import RightArrow from "/assets/arrow_right.svg?react";
import Button from "../button";
import { Column } from "../flex";
import Input from "../input";
import Label from "../label";
import { login } from "../login";
import redirect from "../redirect";
import { useSharedState } from "../state";

export default function RegularLogin() {
	const [state, setState] = useSharedState();
	const { email } = state;

	const setEmail = (email: string) => {
		setState((prev) => ({ ...prev, email }));
	};

	const { sendInfo, sendError } = useErrorHandler();
	const [password, setPassword] = useState("");
	const focusEmail = email.length == 0;
	return (
		<form
			onSubmit={async (e) => {
				e.preventDefault();
				const response = await login(email, password);
				if (response.ok) {
					redirect();
				} else if (response.sso != null) {
					sendInfo(response.message!);
					setState((prev) => ({ ...prev, page: "sso", sso: response.sso }));
				} else {
					sendError(response.message!);
				}
			}}
		>
			<Column gap="600" flex="1 0 auto">
				<Label id="user" label={i18n.getText("weblogin.regular_login.user.label")}>
					{(id) => (
						<Input
							id={id}
							required
							type="text"
							autoComplete="email"
							placeholder={i18n.getText("weblogin.regular_login.user.placeholder")}
							value={email}
							onChange={setEmail}
							autoFocus={focusEmail}
							onEnter={() => {
								document.getElementById("password")?.focus();
							}}
						/>
					)}
				</Label>
				<Column gap="200">
					<Label
						id="password"
						label={i18n.getText("weblogin.regular_login.password.label")}
					>
						{(id) => (
							<Input
								id={id}
								required
								type="password"
								autoComplete="current-password"
								placeholder={i18n.getText(
									"weblogin.regular_login.password.placeholder",
								)}
								value={password}
								onChange={setPassword}
								autoFocus={!focusEmail}
							/>
						)}
					</Label>
					<Button
						type="button"
						style={{ fontSize: "0.8rem" }}
						tertiary
						tabIndex={-1}
						onClick={() => {
							setState((prev) => ({ ...prev, page: "forgot-password" }));
						}}
					>
						{i18n.getText("weblogin.regular_login.forgot_password")}
					</Button>
				</Column>
				<Button
					style={{ marginLeft: "auto" }}
					type="submit"
					disabled={password.length == 0 || email.length == 0}
				>
					{i18n.getText("weblogin.regular_login.login")}
				</Button>
				<Button
					type="button"
					expandWidth
					tertiary
					style={{ marginTop: "auto" }}
					disabled={state.ssoProviders == null}
					onClick={() => {
						setState((prev) => ({ ...prev, page: "sso" }));
						util.setProvider(state.sso);
					}}
				>
					<span>{i18n.getText("weblogin.regular_login.sso_page")}</span>
					<RightArrow width="20px" height="20px" />
				</Button>
			</Column>
		</form>
	);
}
