import { isLoggedIn } from "./api_endpoints/login";
import { FetchError, post } from "./fetch";

type LoginResponse =
	| {
			ok: true;
	  }
	| {
			ok: false;
			message?: string;
			sso?: string;
			message_i18n?: string;
			inner_message?: string;
			final_message_i18n?: string;
	  };

/**
 * log in a user
 * @param usernameOrEmail - username or email address, could be either one
 * @param password - cleartext password
 */
export async function login(_usernameOrEmail: string, password: string): Promise<LoginResponse> {
	const usernameOrEmail = _usernameOrEmail.trim();
	try {
		await post("/py/liveLogin/login", {
			usernameOrEmail,
			password,
		});

		return { ok: true };
	} catch (e: unknown) {
		const error = e as FetchError;
		let sso: string | undefined = undefined;
		if (error.errorKey === "backend.login.no_password_login") {
			const formErrors: { key: string; message: string[] }[] = error.formErrors as any;
			const provider = formErrors.find((entry: { key: string; message: string[] }) => {
				if (entry.key == "sso_provider") return true;
			})?.message[0];
			if (provider) {
				console.log("SET SSO WITH PROVIDER:", provider);
				sso = provider;
			}
		}
		const message =
			error.message ?? "Unknown error.  Please try again or contact Scalgo Live Support.";
		return { ok: false, message, sso };
	}
}

export async function ssoLogin(provider: string): Promise<LoginResponse> {
	localStorage.setItem("sso_msg", "");

	// This gives a session cookie on the pythonweb domain (scalgo.com / beta.scalgo.com) and so forth.
	// So it does not work when serving the way you expect it to. (giving a localhost.scalgo.com cookie).
	const url = `${window.location.origin}/py/sso/request?provider=${provider}&returnto=embedS`;
	const win = window.open(url, "_blank");
	return new Promise((resolve, _) => {
		const handle = setInterval(() => {
			if (win?.closed) {
				window.clearInterval(handle as any);
				// HACK: Check login again to see if SSO Failed.
				isLoggedIn()
					.then(() => {
						resolve({ ok: true });
					})
					.catch(() => {
						const sso_msg = localStorage.getItem("sso_msg") ?? "";
						let final_message_i18n = "";
						if (sso_msg != "") {
							if (sso_msg.includes("another organization")) {
								final_message_i18n = "weblogin.sso_support";
							} else {
								final_message_i18n = "weblogin.local_helpdesk";
							}
						}
						resolve({
							ok: false,
							message_i18n: "weblogin.sso_login_failed",
							inner_message: sso_msg,
							final_message_i18n,
						});
					});
			}
		}, 200);
	});
}
