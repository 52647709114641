export type JSONObject = { [x: string]: JSONValue };
export type JSONArray = Array<JSONValue>;
export type JSONValue = null | string | number | boolean | JSONObject | JSONArray;

export type FetchError = {
	status: number;
	errorKey: string;
	message: string;
} & JSONObject;

async function call<T extends JSONValue>(location: string, init?: RequestInit, timeout = 4000) {
	const controller = new AbortController();
	const signal = controller.signal;
	setTimeout(() => controller.abort(), timeout);
	try {
		const response = await fetch(location, {
			...init,
			signal,
		});

		let result: T;

		const contentType = response.headers.get("content-type");
		if (contentType != "application/json") {
			if (response.ok) {
				return (await response.text()) as unknown as T;
			}
			const error: FetchError = {
				status: response.status,
				message: "",
				errorKey: "wrong_return_type",
				originalError: await response.text(),
			};
			throw error;
		}
		result = (await response.json()) as unknown as T;
		if (!response.ok) {
			const error: FetchError = { ...(result as FetchError), status: response.status };
			throw error;
		}
		return result;
	} catch (e: unknown) {
		if ("errorKey" in (e as Record<string, unknown>)) {
			throw e;
		}
		const fetchError: FetchError = {
			errorKey: "fetch_error",
			message: "Fetch error",
			status: -1,
		};
		throw fetchError;
	}
}

export async function post<T extends JSONValue>(
	location: string,
	data?: { [key: string]: string },
	timeout?: number,
): Promise<T> {
	if (data == null) {
		return call<T>(
			location,
			{
				method: "post",
				credentials: "include",
			},
			timeout,
		);
	}
	const body = new FormData();
	for (const [key, value] of Object.entries(data)) {
		body.append(key, value);
	}
	return call<T>(
		location,
		{
			method: "post",
			credentials: "include",
			body,
		},
		timeout,
	);
}

export async function get<T extends JSONValue>(location: string, timeout?: number): Promise<T> {
	return call<T>(location, undefined, timeout);
}
