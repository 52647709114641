import { useState } from "react";
import { createContainer } from "react-tracked";
import { ToastMessage } from "./toast-handler";

export type SSOProvider = {
	value: string;
	label: string;
};

type Page = "logged-in" | "regular" | "sso" | "forgot-password" | "verify-token" | "reset-password";

export type Info = { type: "info" | "warning" | "error"; message: ToastMessage; duration?: number };

type GlobalState = {
	page: Page;
	email: string;
	resetToken?: string;
	name?: string;
	sso?: string;
	ssoProviders?: SSOProvider[];
	information: { [key: string]: Info };
};

const initialState: GlobalState = {
	page: "regular",
	email: "",
	information: {},
};

const useGlobalState = () => useState(initialState);

export const { Provider: SharedStateProvider, useTracked: useSharedState } =
	createContainer(useGlobalState);
