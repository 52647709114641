// Generated by bind_gen DO NOT EDIT
import { fetchApi, handleNonOkResponse, throwFetchError } from "./_utils";

export type SSOProvider = {
	name: string;
	provider: string;
	selected?: boolean;
};

export async function getProviders() : Promise<SSOProvider[]> {
	let __data__ = "";
	try {
		const r = await fetchApi(
			"/r/sso/get_providers?" + __data__, {
			referrerPolicy: 'origin',
			mode: 'same-origin',
			credentials: 'same-origin',
			method: 'GET'
		});
		if (!r.ok) await handleNonOkResponse(r);
		return await r.json();
	} catch (e) {
		throwFetchError(e);
	}
}
