import util from "./util";

function getRedirectURL(): URL {
	const redirect = util.getQueryParam("redirect");
	if (redirect == null) {
		return new URL(window.location.origin);
	}
	try {
		const redirectUrl = new URL(window.location.origin + redirect);
		return redirectUrl;
	} catch (_) {
		console.warn("Failed to create url from redirect:", redirect);
		return new URL(window.location.origin);
	}
}

export default function redirect() {
	const url = getRedirectURL();
	window.location.href = url.toString();
}
