// This file is generated DO NOT EDIT
export const strings = {
    "da": {
        "weblogin.error.connection": "Netværksfejl, tjek venligst din internetforbindelse og prøv igen."
    },
    "en": {
        "weblogin.button.to_regular_login": "Log in using email and password",
        "weblogin.error.connection": "Network error, please check your network connection and try again.",
        "weblogin.error.reset_token_missing": "Password reset token is missing.",
        "weblogin.error.verify_failed": "Validation of then reset token failed, is it more than 24 hours old or was it already used?",
        "weblogin.forgot_password.email.label": "Email address",
        "weblogin.forgot_password.email.placeholder": "Enter email address",
        "weblogin.forgot_password.reset_password": "Reset password",
        "weblogin.local_helpdesk": "<br></br>Please contact your local IT- or Scalgo Live administrator.",
        "weblogin.logged_in.already_logged_in": "You are already logged in. Here you can log out or continue.",
        "weblogin.logged_in.continue": "Continue",
        "weblogin.logged_in.logout": "Log out",
        "weblogin.regular_login.forgot_password": "Forgot your password?",
        "weblogin.regular_login.login": "Log in",
        "weblogin.regular_login.password.label": "Password",
        "weblogin.regular_login.password.placeholder": "Enter password",
        "weblogin.regular_login.sso_page": "Log in using single sign-on",
        "weblogin.regular_login.user.label": "Email",
        "weblogin.regular_login.user.placeholder": "Enter email",
        "weblogin.reset_password.confirm_password.label": "Confirm password",
        "weblogin.reset_password.confirm_password.placeholder": "Enter password",
        "weblogin.reset_password.greeting": "Hello {name}, you can now proceed to resetting your password.",
        "weblogin.reset_password.password.label": "New password",
        "weblogin.reset_password.password.placeholder": "Enter password",
        "weblogin.reset_password.reset_password": "Reset password",
        "weblogin.sso_login.login": "Log in",
        "weblogin.sso_login.organization.label": "Organisation",
        "weblogin.sso_login.organization.placeholder": "Select SSO Provider",
        "weblogin.sso_login_failed": "SSO Login failed.{inner_message}{final_message}",
        "weblogin.sso_login_failed_inner": "<br></br>Error message: <em>{inner_message}</em>",
        "weblogin.sso_support": "<br></br>Please contact Scalgo Live support for help.  E-mail support@scalgo.com or phone +45 8877 2668.",
        "weblogin.verify_token.continue": "Continue",
        "weblogin.verify_token.info": "We just sent you an email containing a unique code. Copy that code into the box below to continue to reset your password. The code will remain active for 24 hours. Or just close this page and click the link in your email message. That works too.",
        "weblogin.verify_token.token.label": "Verification token",
        "weblogin.verify_token.token.placeholder": "Enter verification token",
        "weblogin.warning.no_sso_selected": "No SSO provider selected.",
        "weblogin.warning.token_not_verified": "Could not verify token from URL."
    }
};
