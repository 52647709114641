import "normalize.css";
import "/styles/index.scss";
import { createRoot } from "react-dom/client";
import Main from "./main";
import { SharedStateProvider } from "./state";

function App() {
	return (
		<SharedStateProvider>
			<Main />
		</SharedStateProvider>
	);
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
